var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group mb-3",class:{'d-flex justify-content-center items-center': _vm.isInline}},[_c('label',{staticClass:"col-form-label font-size-lg",class:{
    'col-form-label-sm': _vm.size.toString() === 'sm',
    'col-form-label-lg': _vm.size.toString() === 'lg',
  },attrs:{"for":_vm.name}},[_vm._v(_vm._s(_vm.title)+" "),(_vm.required)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]),_c('div',{class:{'ml-3': _vm.isInline},style:([_vm.isInline ? {flexGrow: 1} : {}])},[_c('multiselect',{class:{
               'multiple-select form-control': true,
               'form-control-sm': _vm.size.toString() === 'sm',
               'form-control-lg': _vm.size.toString() === 'lg',
              },attrs:{"multiple":true,"max-height":150,"placeholder":_vm.$t('general.select_option'),"tagPlaceholder":_vm.$t('general.please_enter_to_create_tag'),"selectLabel":_vm.$t('general.please_enter_to_select'),"selectGroupLabel":_vm.$t('general.please_enter_to_select_group'),"selectedLabel":_vm.$t('general.selected'),"openDirection":"below","deselectLabel":_vm.$t('general.please_enter_to_remove'),"deselectGroupLabel":_vm.$t('general.please_enter_to_deselect_group'),"disabled":_vm.disabled,"options":_vm.values},model:{value:(_vm.modelProxy),callback:function ($$v) {_vm.modelProxy=$$v},expression:"modelProxy"}})],1),(_vm.helperText)?_c('info-tooltip',{attrs:{"helper-text":_vm.helperText}}):_vm._e(),(_vm.error)?_c('p',{staticClass:"text-danger pt-1 px-3 block"},[_vm._v(_vm._s(_vm.error))]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }