<template>
  <div class="form-group mb-3" :class="{'d-flex justify-content-center items-center': isInline}">
    <label :for="name" class="col-form-label font-size-lg" :class="{
      'col-form-label-sm': size.toString() === 'sm',
      'col-form-label-lg': size.toString() === 'lg',
    }">{{ title }}
      <span class="text-danger" v-if="required">*</span>

    </label>
    <div class="" :style="[isInline ? {flexGrow: 1} : {}]"
      :class="{'ml-3': isInline}"
    >
      <multiselect
          :multiple="true"
          :max-height="150"
          :placeholder="$t('general.select_option')"
          :tagPlaceholder="$t('general.please_enter_to_create_tag')"
          :selectLabel="$t('general.please_enter_to_select')"
          :selectGroupLabel="$t('general.please_enter_to_select_group')"
          :selectedLabel="$t('general.selected')"
          openDirection="below"
          :deselectLabel="$t('general.please_enter_to_remove')"
          :deselectGroupLabel="$t('general.please_enter_to_deselect_group')"
          :disabled="disabled"
          v-model="modelProxy"
          :options="values"
          :class="{
                 'multiple-select form-control': true,
                 'form-control-sm': size.toString() === 'sm',
                 'form-control-lg': size.toString() === 'lg',
                }"
      ></multiselect>

    </div>
    <info-tooltip
        :helper-text="helperText"
        v-if="helperText"
    ></info-tooltip>
    <p class="text-danger pt-1 px-3 block" v-if="error">{{ error }}</p>
  </div>

</template>

<script>

import 'vue-multiselect/dist/vue-multiselect.min.css'
import InfoTooltip from "@/assets/components/widget/InfoTooltip";

export default {
  name: "MultipleSelectInput",
  props: {
    title: {},
    name: {},
    values: {},
    error: {},
    model: {},
    required: {
      required: false,
      default: false,
    },
    disabled: {
      required: false,
      default: false
    },
    multiple: {
      required: false,
      default: false,
    },
    helperText: {
      default: null,
      required: false,
      type: String,
    },
    size: {
      default: 'sm',
      validator: function validator(l) {
        return ['xs', 'sm', 'md', 'lg'].indexOf(l) > -1;
      }
    },
    isInline: {
      required: false,
      default:true,
    },
  },
  components: {
    InfoTooltip
  },
  computed: {
    modelProxy: {
      get() {
        return this.model
      },
      set(value) {
        this.$emit('update:model', value)
      }
    }
  },
}
</script>

<style type="text/css">
.multiselect{
  height: 40px !important;
  padding: 0 !important;
}
.multiselect__tags{
  /*padding-top: 0 !important;*/
  background: transparent !important;
}
.multiselect__placeholder{
  margin-bottom: 0 !important;
}

.multiselect {
  border: none !important;
}

.form-control .multiselect__tags {
  border-radius: 25px !important;
}


/*.multiselect--disabled {*/
/*  .multiselect__tags{*/
/*    background: #ecf0f3 !important;*/
/*    opacity: 1 !important;*/
/*  }*/
/*}*/

/*.multiselect--disabled > .multiselect__tags {*/
/*  background: #ecf0f3 !important;*/
/*}*/
</style>
